<template>
  <div>
    <v-card>
      <v-card-title>
        <span>ตั้งค่าโปรไฟล์</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>

      <v-row class="px-2">
        <v-col cols="12" md="6">
          <v-text-field v-model="fname" filled hide-details dense label="ชื่อ" placeholder="ชื่อ"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="lname" filled hide-details dense label="นามสกุล"
                        placeholder="นามสกุล"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="username" disabled filled hide-details dense label="Username"
                        placeholder="Username"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field disabled v-model="password" filled hide-details dense label="Password"
                        type="password"
                        placeholder="Password"></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="updateProfile">บันทึก</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api"
import { mdiPencilOutline } from "@mdi/js"
import { api } from "@/services/api"
import store from "@/store";

export default {
  props: {},
  components: {},
  setup(_, { root, emit }) {
    const fname = ref('')
    const lname = ref('')
    const username = ref('')
    const password = ref('*******')
    const imageProfile = ref('')

    onMounted(() => {
      api.get({
        path: '/profile'
      }).then(({ data }) => {
        fname.value = data.user_fname
        lname.value = data.user_lname
        username.value = data.username
      }).catch(err => {
        console.log('err', err)
      })
    })

    const updateProfile = () => {
      api.put({
        path: '/profile',
        body: {
          user_fname: fname.value,
          user_lname: lname.value,
        }
      }).then(res => {
        store.commit('app/ALERT', {
          message: 'บันทึกข้อมูลเรียบร้อย',
          color: 'success',
        })

      }).catch(err => {
        console.log('err', err)
      })
    }

    const previewFiles = () => {

    }

    return {
      fname,
      lname,
      updateProfile,
      username,
      password,
      imageProfile,
      previewFiles,
      mdiPencilOutline,
    }
  },

}
</script>

<style scoped>

</style>
